import { useEffect, useState } from "react"
import { useParams,Link } from 'react-router-dom';
import { AiOutlineLink } from "react-icons/ai";
import { useNavigate,useLocation } from "react-router-dom";


import Header from "../../component/header"
import Footer from "../../component/footer";
import ErrorContent from "../../component/error";
import Helm from "../../component/head";

// const initdata = {
//   "title": "Loading...",
//   "data": [
//       {
//         "key": "Loading...",
//         "value": "Loading...",
//       }
//   ]
// }

// const initdata = {
//   "title": "主な栄養素の一日あたりの食事摂取基準",
//   "data": [
//       {
//         "key": "エネルギー",
//         "value": "2650kcal",
//       },{
//         "key": "タンパク質",
//         "value": "65g"
//       },{
//         "key": "脂質（目標量）",
//         "value": "20～30%	"
//       },{
//         "key": "ビタミンA※1	",
//         "value": "850µg"
//       },
//   ]
// }

// const initdata = {
//   "title": "Amazonおすすめリスト",
//   "data": [
//     {
//       key: 'いにD',
//       value: 'https://www.amazon.co.jp/gp/video/detail/B099WCLXWW/ref=atv_hm_hom_1_c_kt5S1U_brws_3_1',
//       page: {
//         pagetitle: 'Amazon.co.jp: 頭文字Ｄ 4th stageを観る | Prime VideoAddAddShareShareEditEditLightbulbLightbulb'
//       }
//     },{
//       value: 'いにD',
//       key: 'https://www.amazon.co.jp/gp/video/detail/B099WCLXWW/ref=atv_hm_hom_1_c_kt5S1U_brws_3_1',
//       // page: {
//       //   pagetitle: 'Amazon.co.jp: 頭文字Ｄ 4th stageを観る | Prime VideoAddAddShareShareEditEditLightbulbLightbulb'
//       // }
//     },{
//       value: 'looooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooongstring',
//       key: 'looooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooongstring',
//       // page: {
//       //   pagetitle: 'Amazon.co.jp: 頭文字Ｄ 4th stageを観る | Prime VideoAddAddShareShareEditEditLightbulbLightbulb'
//       // }
//     },
//     {
//       key: 'とんつう',
//       value: 'https://tabelog.com/tokyo/A1312/A131201/13045164/',
//       page: {
//         pagetitle: 'とんつう - 錦糸町/ホルモン | 食べログ',
//         favicon: 'https://tblg.k-img.com/favicon.ico?20170919',
//         ogtitle: 'とんつう (錦糸町/ホルモン)'
//       }
//     },
//     {
//       key: 'URL',
//       value: 'https://www.amazon.co.jp/dp/B07BKYRW9M/?th=1',
//       page: {
//         pagetitle: 'Amazon.co.jp : アイリスオーヤマ テーブル サイドテーブル コの字型デザイン 木目調 ブラウンオーク/ブラック 幅約45×奥行約29×高さ約52.2cm SDT-45 : ホーム＆キッチン'
//       }
//     },
//   ]
// }


const Note = () => {
  const { id } = useParams()
  const [data, setData] = useState()
  useEffect(()=>{
    (async() => {
      await fetch('/api/note/'+id)
        .then(async res => setData(await res.json()))
    })()
  },[id])
  return(
    <>
      {data
      ? <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
          <Helm title={data.title} id={id} />
          <Header />
          <Content data={data}/>
          <CreateTodoANDRemove id={id}/>
          <Footer />
        </div>
      : <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
          <Helm title={'loading...'} id={id}/>
          <Header />
          <LoadingContent />
          <Footer />
        </div>
      }
    </>
  )
}

export default Note

const Content = (props) => {
  const data= props.data
  if(data.error) return <ErrorContent />
  return (
    <div className="flex flex-col p-2 max-w-3xl w-full bg-white">
      <table className="border-collapse">
        <caption className="font-extrabold text-3xl p-2 items-center justify-center mb-4 w-full">
          {data.title}
        </caption>
        <tbody>
          { data.data.map((d,index) => <TableRow data={d} index={index} key={index}/>)}
        </tbody>
      </table>
    </div>
  )
}

const LoadingContent = () =>{
  return(
    <div className="animate-pulse flex space-x-4">
      <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="loading animation" />
    </div>
  )
}

const CreateTodoANDRemove = (props) =>{
  const button = "p-2 rounded-lg flex items-center justify-center border-black  bg-gray-200 transition duration-500 hover:bg-gray-700 hover:text-white"
  const [modal,setModal] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const deletehandler = async() => {
    const response = await fetch('/api/delete/'+id)
    const d = await response.json()
    if(d.error){
      navigate('/api/login?cb=' + encodeURI(location.pathname))
    }
    if(d.status){
      navigate('/user')
    }
    return
  }

  return(
    <div className="mt-8 flex flex-col gap-2">
      <Link to='/note' className={button}>新規にリストを作る</Link>
      <Link to={'/note?clone='+props.id} className={button}>これを元に新しいリストを作る</Link>
      <div className="flex gap-2">
        <button className="bg-red-100 p-2 rounded-lg hover:bg-red-300 transition duration-500" onClick={()=>setModal(true)}>
          削除
        </button>
        { modal === true  &&
          <div className="z-10 bg-slate-300 bg-opacity-75 modal fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto transition duration-500 flex justify-center items-center">
            <div className="z-20 bg-white bg-opacity-100">
              <div className="w-64 h-20  flex justify-center items-center">
                <div>削除しますか？</div>
              </div>
              <div className="flex px-2 justify-around pb-2">
              <button className="bg-red-100 w-16 py-2 rounded-lg hover:bg-red-300 transition duration-500" onClick={()=>deletehandler()}>はい</button>
              <button className="bg-red-100 w-16 py-2 rounded-lg hover:bg-red-300 transition duration-500" onClick={()=>setModal(false)}>いいえ</button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const TableRow = (props) => {
  const data = props.data
  const page = props.data.page || undefined
  return(
    <tr>
      { page
        ? <>
            <LocalCheck index={props.index} />
            <LinkRow data={data}  />
          </>
        : <>
            <LocalCheck index={props.index} />
            <SimpleText text={data.key} />
            <SimpleText text={data.value} />
          </>
      }
    </tr>
  )
}

const LocalCheck = (props) => {
  const lk = new URL(window.location.href).href + '/check/' + props.index
  const ls = localStorage.getItem(lk)
  const handleChange = async e => {
    const bool = e.target.checked
    const strbool = bool.toString()
    localStorage.setItem(lk,strbool)
  }
  return(
    <td className="" >
      <div className="">
      {
        ls === 'true'
        ? <input className="border-gray-300 rounded h-8 w-8 accent-gray-700 transition duration-500" type="checkbox" onChange={handleChange} defaultChecked='checked' />
        : <input className="border-gray-300 rounded h-8 w-8 accent-gray-700 transition duration-500" type="checkbox" onChange={handleChange} />
      }
      </div>
    </td>
  )
}

const LinkRow = (props) =>{
  return(
    <>
    {
      props.data.key === 'url' ||  props.data.key === 'URL'
      ? <BigURLButtonRow data={ props.data } />
      : <LittleURLDataRow data={ props.data } />
    }
    </>
  )
}

const BigURLButtonRow = (props) =>{
  const title = props.data.page.ogtitle ? props.data.page.ogtitle : props.data.page.pagetitle
  const spl = title.match(/.{1,60}/g)
  const shorttitle = spl[0] + (spl[1]? '...' : '')
  return(
    <td className="w-1/2" colSpan={2}>
      <LinkButton data={props.data} shorttitle={shorttitle}/>
    </td>
  )
}

const LittleURLDataRow = (props)=>{
  const title = props.data.page.ogtitle ? props.data.page.ogtitle : props.data.page.pagetitle
  const spl = title.match(/.{1,40}/g)
  const shorttitle = spl[0] + (spl[1]? '...' : '')
  return (
    <>
    <SimpleText text={props.data.key} />
    <td className="w-1/2">
      <LinkButton data={props.data} shorttitle={shorttitle}/>
    </td>
    </>
  )
}

const LinkButton = (props) =>{
  const domain = new URL(props.data.value).origin
  return(
    <a
      href={props.data.value}
      className='p-2 rounded-lg flex items-center justify-center border-black  bg-gray-200 transition duration-500 hover:bg-gray-700 hover:text-white'>
      {props.data.page.favicon ? <img className="w-6 h-6 mx-2 shrink-0" src={props.data.page.favicon} alt='favicon' /> : <AiOutlineLink className="w-6 h-6 mx-2 shrink-0"/>}
      <div className="flex flex-col justify-center">
        <span className="break-all font-bold">{props.shorttitle}</span>
        <span className="text-xs">{domain}</span>
      </div>
    </a>
  )
}

const SimpleText = (props)=>{
  const text = props.text
  return(
    <td className="w-1/2">
      <div className="break-all  font-semibold px-2">
        {text}
      </div>
    </td>
  )
}
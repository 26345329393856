const Footer = () => {
  return(
    <footer className="flex flex-col mt-auto justify-center">
      <nav className="p-4">
      </nav>
      <div className="font-mono p-4">©petit Share</div>

    </footer>
  )
}

export default Footer
import useSWR from 'swr'
import { Link } from 'react-router-dom';

import Header from "../../component/header"
import Footer from "../../component/footer";
import { Helmet } from "react-helmet-async";

const fetcher = (...args) => fetch(...args).then(res => res.json())

const UserTodos = () => {
  const { data, error, isLoading } = useSWR('/api/user/notes', fetcher)

  if(isLoading) return (
    <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
      <Helm title={'loading...'}/>
      <Header />
      <LoadingContent />
      <Footer />
    </div>
  )
  if(error) return (
    <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
      <Helm title={'error'}/>
      <Header />
      <ErrorContent />
      <Footer />
    </div>
  )
  return(
  <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
    <Helm title={'一覧'}/>
    <Header />
    <Content data={data}/>
    <Footer />
  </div>
  )
}

export default UserTodos

const Helm = (props) => {
  return (
    <Helmet>
      <title>{ props.title + ' | petitShare'}</title>
    </Helmet>
  )
}

const LoadingContent = () =>{
  return(
    <div className="animate-pulse flex space-x-4">
      <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
    </div>
  )
}

const ErrorContent = () =>{
  return(
    <div className="flex space-x-4">
      <div>something error...</div>
    </div>
  )
}

const Content = (props) => {
  if(props.data === []){
    return(
      <div className="flex flex-col gap-2">
        <NewToDoButton />
      </div>
    )
  }
  return(
    <div className="flex flex-col gap-2">
      {props.data.map((d)=>
      <div className="flex items-center gap-2 p-2 bg-slate-200 justify-between">
        <div className="truncate">
          {d.title}
        </div>
        <Link to={"/note/" + d.id} className='bg-white p-2 rounded'>
          <button className="whitespace-pre">移動</button>
        </Link>
        <NewToDoButton />
      </div>
      )}
    </div>
  )
}

const NewToDoButton = () => { 
  return(
    <div>新しくToDoを作ってみましょう。
    <Link to={"/note"} className='bg-white p-2 rounded'>
      <button className="whitespace-pre">移動</button>
    </Link>
    </div>
  )
}
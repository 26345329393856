
const Header = () => {
  return(
    <header className="flex flex-col w-4/5 md:w-96">
      <a href='/'>
        <img src ='/logo.png' alt='1' className="md:py-12 self-center" />
      </a>
    </header>
  )
}

export default Header
import Helmet from 'react-helmet';

const Helm = (props) => {
  return (
  <Helmet
    title={props.title + ' | petitShare'}
    meta={[
      {name:'og:url',content :'https://pgs.gawgaw.tk/note/' + props.id },
      {name:'og:title',content : props.title + ' | petit Share'},
      {name:'og:image',content :'https://pgs.gawgaw.tk/api/ogp/' + props.id || 'home' },
      {name:'og:description',content :'petit shareはシンプルなToDoリスト共有サービスです。' },
      {name:'twitter:card',content :'summary_large_image'},
    ]}
  />
  )
}




export default Helm
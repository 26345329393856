import Header from "../../component/header"
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Footer from "../../component/footer";
import {  useSearchParams, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import useSWR from 'swr'
import LoadingContent from "../../component/loading";
import ErrorContent from "../../component/error";

const initdata = {
  "title": "Amazonおすすめリスト",
  "data": [
    {
      key: 'いにD',
      value: 'https://www.amazon.co.jp/gp/video/detail/B099WCLXWW/ref=atv_hm_hom_1_c_kt5S1U_brws_3_1',
      page: {
        pagetitle: 'Amazon.co.jp: 頭文字Ｄ 4th stageを観る | Prime VideoAddAddShareShareEditEditLightbulbLightbulb'
      }
    },{
      value: 'いにD',
      key: 'https://www.amazon.co.jp/gp/video/detail/B099WCLXWW/ref=atv_hm_hom_1_c_kt5S1U_brws_3_1',
      // page: {
      //   pagetitle: 'Amazon.co.jp: 頭文字Ｄ 4th stageを観る | Prime VideoAddAddShareShareEditEditLightbulbLightbulb'
      // }
    },{
      value: 'looooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooongstring',
      key: 'looooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooongstring',
      // page: {
      //   pagetitle: 'Amazon.co.jp: 頭文字Ｄ 4th stageを観る | Prime VideoAddAddShareShareEditEditLightbulbLightbulb'
      // }
    },
    {
      key: 'とんつう',
      value: 'https://tabelog.com/tokyo/A1312/A131201/13045164/',
      page: {
        pagetitle: 'とんつう - 錦糸町/ホルモン | 食べログ',
        favicon: 'https://tblg.k-img.com/favicon.ico?20170919',
        ogtitle: 'とんつう (錦糸町/ホルモン)'
      }
    },
    {
      key: 'URL',
      value: 'https://www.amazon.co.jp/dp/B07BKYRW9M/?th=1',
      page: {
        pagetitle: 'Amazon.co.jp : アイリスオーヤマ テーブル サイドテーブル コの字型デザイン 木目調 ブラウンオーク/ブラック 幅約45×奥行約29×高さ約52.2cm SDT-45 : ホーム＆キッチン'
      }
    },
  ]
}


const fetcher = (...args) => fetch(...args).then(res => res.json())

const Create = () => {
  const [searchParams] = useSearchParams();
  const clone = searchParams.get('clone')
  if(!clone){
    return(
      <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
        <Helm title={'新規リスト作成 | petitShare'}></Helm>
        <Header />
        <Content />
        <Footer />
      </div>
    )
  }
  return <CloneCreate id={clone}/>
}

export default Create

const CloneCreate = (props) => {
  const { data, error, isLoading } = useSWR('/api/note/'+props.id, fetcher)
  if(isLoading) return (
    <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
      <Helm title={'loading...'}/>
      <Header />
      <LoadingContent />
      <Footer />
    </div>
  )
  if(error) return (
    <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
      <Helm title={'error'}/>
      <Header />
      <ErrorContent />
      <Footer />
    </div>
  )
  return (
    <div className="flex flex-col mx-auto items-center gap-2 selection:bg-black selection:text-white min-h-screen">
      <Helm title={'新規リスト作成 | petitShare'}></Helm>
      <Header />
      <Content data={data}/>
      <Footer />
    </div>
  )
}


const Content = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const nulldata = {
    title : "",
    data: [
      { key: "", value: "" },
    ],
  }
  const { register, control, handleSubmit,} = useForm({
    defaultValues: props.data ? props.data : nulldata
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "data"
  })
  const sendData = async data => {
    const response = await fetch('/api/note',{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    })
    const d = await response.json()
    if(d.error){
      navigate('/api/login?cb=' + encodeURI(location.pathname))
    }
    const url = new URL(d.url).pathname
    navigate(url)
    return
  }
  const inputclass = 'w-full rounded px-1 py-1 text-black border border-gray-300 focus:ring-gray-300 focus:shadow-md focus:outline-none ring-offset-2 focus:ring-2 focus:ring-gray-300'
  return (
    <div className="max-w-3xl w-full">
    <form className="selection:bg-black selection:text-white" onSubmit={handleSubmit(sendData)}>
      <ul className="flex flex-col gap-2">
        <div className="bg-white text-black grid grid-cols-2 gap-2 p-2 border-gray-300 rounded items-center">
          <div className="px-2">タイトル</div>
          <input {...register(`title`,{ required: true })} className={inputclass} placeholder='title'/>
        </div>
          { fields.map((item, index) => (
            <div className="bg-white text-black">
              <li key={item.id} className = 'bg-white flex gap-2 p-2 border-gray-300 rounded'>
                <input {...register(`data.${index}.key`,{ required: true })} className={inputclass} placeholder='key'/>
                <Controller
                  render={({ field }) => <input  {...field} className={inputclass} placeholder='val'/> }
                  rules={{ required: true }}
                  name={`data.${index}.value`}
                  control={control}
                />
                <button type="button"
                  className="p-2 rounded flex items-center justify-center border-black  bg-gray-200 transition duration-500 hover:bg-gray-700 hover:text-white"
                  onClick={() => remove(index)}>
                    delete
                </button>
              </li>
            </div>
        ))}
      <div className='bg-white text-black grid grid-cols-2 gap-2 p-2 rounded items-center'>
        <button
          type="button"
          className="p-2 rounded flex items-center justify-center border-black  bg-gray-200 transition duration-500 hover:bg-gray-700 hover:text-white"
          onClick={() => append({ key: "", value: "" })}
        >
          追加
        </button>
        <input
          className="p-2 rounded flex items-center justify-center border-black  bg-gray-200 transition duration-500 hover:bg-gray-700 hover:text-white"
          type="submit" />
        </div>
      </ul>
    </form>
    </div>
  );
}

const Helm = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
    </Helmet>
  )
}
import Helm from "../../component/head"
import Header from "../../component/header"
import LoginButton from "../../component/loginbutton"

const Index = () => {
  return(
    <div className="flex flex-col mx-auto items-center max-w-screen-lg gap-2">
      <Helm title = 'home' id = 'home' />
      <Header />
      <LoginButton />
      <Content />
    </div>
  )
}

export default Index

const Content = () => {
  return (
    <div className="flex justify-evenly">
      <JPdescription />
    </div>
  )
}

// マシュマロとは？
// 匿名のメッセージを受け付けるサービスです
// ネガティブな内容のものはAIがこっそり削除しちゃうので、ポジティブなものだけがあなたに届きます
// このサービスは「世界はもっとマシュマロを投げ合うような安全さで満たされるべき」という思想で作られています

const JPdescription = () => {
  return(
    <div className = "p-4">
      <h2 className="text-4xl font-mono py-8">
        petit shareはシンプルなToDoリスト共有サービスです。
      </h2>
      <div className="rounded border">
        <h2 className="text-xl p-4">
          特徴的な機能
        </h2>
        <ul className="text-lg p-4">
          <li>
            1.各項目に☑チェックボックスがあります。(ブラウザに保存されます。)
          </li>
          <li>
            2.コピーアンドペーストすることでスプレッドシートに貼り付けできます。
          </li>
          <li>
            3.リストは後から編集されません。
          </li>
        </ul>
      </div>
      <div className="rounded border">
        <h2 className="text-xl p-4">
          リストの作成方法
        </h2>
        <ul className="text-lg p-4">
          <li>
            1.Twitterでログインします。
          </li>
          <li>
            2.リスト📋を作成します。
          </li>
          <li>
            3.新規作成をすると自分宛にDM📩でURLが送られます。
          </li>
          <li>
            4.URLを共有しましょう🎉
          </li>
        </ul>
      </div>
    </div>
  )
}
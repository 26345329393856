import { AiFillTwitterSquare } from "react-icons/ai";


const LoginButton = (props) => {
  const url = props.id ? '/api/login?id='+props.id : '/api/login'
  return(
    <div className="py-8">
    <a
      href= {url}
      className="flex justify-center bg-blue-700 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm text-center items-center h-10 w-40">
      <div className='h-8 w-8'>
        <AiFillTwitterSquare className="w-full h-full" />
      </div>
      <span>
        Login at Twitter
      </span>
    </a>
    </div>
  )
}

export default LoginButton

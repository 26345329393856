import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import Note from './pages/note'
import Create from './pages/create'
import Index from './pages/index'
import UserTodos from './pages/user';

const router = createBrowserRouter([
  {
    path:'note/:id',
    element:<Note />,
    errorElement:<>error</>,
  },{
    path:'note',
    element:<Create /> ,
    errorElement:<>error</>,
  },{
    path:'user',
    element:<UserTodos />,
    errorElement:<>error</>,
  },{
    path:'report/:id',
    element:<UserTodos />,
    errorElement:<>error</>,
  },{
    path:'/',
    element:<Index />,
    errorElement:<>error</>,
  }])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router}/>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
